import { useContext } from "react"
import ThemeContext from "../../store/ThemeContext"

function Pause({ fill = "#000000", onClick }) {
  const themeCtx = useContext(ThemeContext)

  if (fill === "#000000") {
    fill = themeCtx.darkMode ? "#FFFFFF" : "#000000"
  }

  return (
    <svg
      className="pause-icon"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill}
      onClick={onClick || null}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path
          fill={fill}
          d="M120.16 45A20.162 20.162 0 0 0 100 65.16v381.68A20.162 20.162 0 0 0 120.16 467h65.68A20.162 20.162 0 0 0 206 446.84V65.16A20.162 20.162 0 0 0 185.84 45h-65.68zm206 0A20.162 20.162 0 0 0 306 65.16v381.68A20.162 20.162 0 0 0 326.16 467h65.68A20.162 20.162 0 0 0 412 446.84V65.16A20.162 20.162 0 0 0 391.84 45h-65.68z"
        ></path>
      </g>
    </svg>
  )
}
export default Pause
